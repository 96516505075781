import React, { useEffect } from "react";

declare global {
    interface Window {
        particlesJS: any;
    }
}

export default function Particles() {
    useEffect(() => {
        window.particlesJS("particles-js", {
            particles: {
                number: {
                    value: 160,
                    density: { enable: true, value_area: 800 },
                },
                color: { value: "#AAA" },
                shape: {
                    type: "circle",
                    stroke: { width: 0, color: "#000000" },
                    polygon: { nb_sides: 5 },
                },
                opacity: {
                    value: 1,
                    random: true,
                    anim: {
                        enable: true,
                        speed: 1,
                        opacity_min: 0,
                        sync: false,
                    },
                },
                size: {
                    value: 3,
                    random: true,
                    anim: {
                        enable: false,
                        speed: 4,
                        size_min: 0.3,
                        sync: false,
                    },
                },
                line_linked: {
                    enable: false,
                    distance: 150,
                    color: "#AAA",
                    opacity: 0.4,
                    width: 1,
                },
                move: {
                    enable: true,
                    speed: 1,
                    direction: "none",
                    random: true,
                    straight: false,
                    out_mode: "out",
                    bounce: false,
                    attract: { enable: false, rotateX: 600, rotateY: 600 },
                },
            },
            interactivity: {
                detect_on: "canvas",
                events: {
                    onhover: { enable: false },
                    onclick: { enable: false },
                    resize: false,
                }
            },
            retina_detect: true,
        });
    });
    return <div id="particles-wrapper"><div id="particles-js"></div></div>
}