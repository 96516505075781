import "./Shortcuts.less";

export default function Shortcuts() {
    const shortcuts = [
        {
            keys: ["⌘","B"],
            description: "Importance (Bold)"
        },
        {
            keys: ["⌘","I"],
            description: "Emphasis (Italic)"
        },
        {
            keys: ["⌘","L"],
            description: "Unordered List"
        },
        {
            keys: ["⌘", "⇧", "L"],
            description: "Ordered List"
        },
        {
            keys: ["⌘","K"],
            description: "Add Link"
        },
        {
            keys: ["⌘","1-6"],
            description: "Heading 1 - 6"
        },
        {
            keys: ["⌘", "0"],
            description: "Blockquote"
        }
    ];

    const renderKeys = (keys: Array<string>) => {
        const node = keys.map((key, i) => {
            const node = <span className="key">{ key }</span>;
            let combine = <>&nbsp;+&nbsp;</>;
            if (keys.length - 1 === i) combine = <></>;
            return <span key={i}>{node}{combine}</span>;
        });
        return node;
    }

    return <ul id="shortcuts" className="listing">
        {shortcuts.map((shortcut, i) => {
            return (
                 <li key={i}>
                    <div className="shortcut">
                        { renderKeys(shortcut.keys) }
                    </div>
                    <div className="description">
                        { shortcut.description }
                    </div>
                </li>
            );
        })}
       
    </ul>
}