import iconFeature from "../assets/feature.svg";

export default function Features() {
    const features = [
        "No dependencies",
        "Syntax highlighting",
        "Quick shortcuts",
        "Markdown toggle",
        "Escaping HTML",
        "Extensible"
    ];

    return <ul className="listing">
        {
            features.map((feature, idx) => {
                return <li className="feature" key={idx}><img src={iconFeature} alt="TinyMDE feature" />{ feature }</li>;
            })
        }
    </ul>
}