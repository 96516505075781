import Editor from "./components/Editor";
import Shortcuts from "./components/Shortcuts";
import Features from "./components/Features";
import Particles from "./components/Particles";

import "./style.less"

function App() {
    const website = "http://betamark.com";
    return <div className="App">
        <Particles />
        <div id="page-wrapper">
            <div id="header">
                <h1>TinyMDE</h1>
                <h4>A simple Markdown editor, in plain JavaScript</h4>
            </div>
            <Editor />
            <div id="content">
                <div className="columns">
                    <div className="column">
                        <h3>FEATURES</h3>
                        <Features />
                    </div>
                    <div className="column">
                        <h3>KEYBOARD SHORTCUTS</h3>
                        <Shortcuts />
                    </div>
                </div>
            </div>
            <div id="footer">
                Another thing from <a href={website} target="_blank">Shlomi Nissan</a>
            </div>
        </div>
    </div>;
}

export default App;
